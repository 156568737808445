var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "fieldset",
        [
          _c(
            "legend",
            [
              _c("a-icon", { attrs: { type: "home" } }),
              _vm._v(" " + _vm._s(_vm.$t("lbl_address")) + " ")
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    { attrs: { align: "baseline" } },
                    [
                      _c("h1", [
                        _vm._v(_vm._s(_vm.$t("lbl_shipping_address")))
                      ]),
                      _c("a-button", {
                        attrs: {
                          "data-testid": "contact-address-manage-ship",
                          icon: "plus",
                          shape: "circle",
                          size: "small"
                        },
                        on: { click: _vm.showManageAddress }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    { attrs: { align: "baseline" } },
                    [
                      _c("h1", [_vm._v(_vm._s(_vm.$t("lbl_billing_address")))]),
                      _c("a-button", {
                        attrs: {
                          "data-testid": "contact-address-manage-bill",
                          icon: "plus",
                          shape: "circle",
                          size: "small"
                        },
                        on: { click: _vm.showManageAddress }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "ant-form-text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("lbl_total_items", {
                          total: _vm.shipingAddressList.length
                        })
                      )
                    )
                  ]),
                  _c("a-list", {
                    attrs: {
                      "data-source": _vm.shipingAddressList,
                      bordered: "",
                      "item-layout": "vertical"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "renderItem",
                        fn: function(item) {
                          return [
                            _c("DisplayContactAddress", {
                              attrs: { address: item },
                              on: {
                                edit: function($event) {
                                  return _vm.handleEditAddress(item)
                                },
                                delete: function($event) {
                                  return _vm.handleDeleteAddress(item)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "ant-form-text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("lbl_total_items", {
                          total: _vm.billingAddressList.length
                        })
                      )
                    )
                  ]),
                  _c("a-list", {
                    attrs: {
                      "data-source": _vm.billingAddressList,
                      bordered: "",
                      "item-layout": "vertical"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "renderItem",
                        fn: function(item) {
                          return [
                            _c("DisplayContactAddress", {
                              attrs: { address: item },
                              on: {
                                edit: function($event) {
                                  return _vm.handleEditAddress(item)
                                },
                                delete: function($event) {
                                  return _vm.handleDeleteAddress(item)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-drawer",
        {
          attrs: {
            "data-testid": "contact-address-manage-drawer",
            visible: _vm.isShowManage,
            closable: "",
            "destroy-on-close": "",
            "mask-closable": false,
            title: _vm.$t("common.manage-text", {
              text:
                _vm.dtManageAddress && _vm.dtManageAddress.asShip
                  ? _vm.$t("lbl_shipping_address")
                  : _vm.$t("lbl_billing_address")
            }),
            placement: "right",
            width: "920"
          },
          on: { close: _vm.onCloseManageAddress }
        },
        [
          _c("FormContactManageAddress", {
            attrs: {
              address: _vm.dtManageAddress,
              "is-create": _vm.isCreate,
              addresses: _vm.formState.addresses
            },
            on: {
              close: _vm.onCloseManageAddress,
              submit: _vm.onSubmitManageAddress
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }