var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        value: _vm.value,
        "allow-clear": "",
        "show-search": "",
        "option-filter-prop": "children",
        "dropdown-match-select-width": false,
        "filter-option": _vm.useLocalFilter,
        disabled: _vm.disabled
      },
      on: { change: _vm.emits }
    },
    [
      _vm._t(
        "options",
        function() {
          return _vm._l(_vm.options, function(item) {
            return _c(
              "a-select-option",
              { key: item.key, attrs: { value: item.value } },
              [
                _vm._t(
                  "default",
                  function() {
                    return [
                      _c("a-tooltip", [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(item.label))]
                        ),
                        _vm._v(" " + _vm._s(item.label) + " ")
                      ])
                    ]
                  },
                  { option: item }
                )
              ],
              2
            )
          })
        },
        { options: _vm.options }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }