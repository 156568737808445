





































import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ContactFormState } from "@/store/models/Contact.model";
import Vue from "vue";
import { mapState } from "vuex";

type Field = {
  label: string;
  prop: string;
  placeholder: string;
  loading: boolean;
  options: Option[];
  isSearch: boolean;
};

export default Vue.extend({
  name: "FormContactAccount",
  computed: {
    ...mapState({
      formState: (st: any) =>
        st.contactStore.contactFormState as ContactFormState,
    }),
  },
  data() {
    this.onSearch = debounceProcess(this.onSearch, 500);
    return {
      loading: {
        account: false,
      },
      accountOptions: [] as Option[],
      fields: [
        {
          label: this.$t("lbl_receivable_account"),
          prop: "receivable",
          placeholder: this.$t("common.select-text", {
            text: this.$t("lbl_receivable_account"),
          }),
          loading: false,
          options: [],
          isSearch: false,
        },
        {
          label: this.$t("lbl_revenue_account"),
          prop: "revenue",
          placeholder: this.$t("common.select-text", {
            text: this.$t("lbl_revenue_account"),
          }),
          loading: false,
          options: [],
          isSearch: false,
        },
        {
          label: this.$t("lbl_payable_account"),
          prop: "payable",
          placeholder: this.$t("common.select-text", {
            text: this.$t("lbl_payable_account"),
          }),
          loading: false,
          options: [],
          isSearch: false,
        },
        {
          label: this.$t("lbl_prepayment_account"),
          prop: "prepayment",
          placeholder: this.$t("common.select-text", {
            text: this.$t("lbl_prepayment_account"),
          }),
          loading: false,
          options: [],
          isSearch: false,
        },
      ] as Field[],
    };
  },
  mounted() {
    this.fetchAccountList();
  },
  methods: {
    async onSearch(field: Field, value?: string) {
      const { filterBy, findAllChildAccount, toOptions } = useCoa();
      field.isSearch = true;
      const params = new RequestQueryParams();
      if (value) {
        params.search = filterBy({ code: value, description: value });
      }
      try {
        field.loading = true;
        const response = await findAllChildAccount(params);
        field.options = toOptions(response.data);
      } finally {
        field.loading = false;
      }
    },
    async fetchAccountList(params?: RequestQueryParamsModel) {
      const { findAllChildAccount, toOptions } = useCoa();
      try {
        this.loading.account = true;
        const response = await findAllChildAccount(params);
        this.accountOptions = toOptions(response.data);
      } finally {
        this.loading.account = false;
      }
    },
  },
});
