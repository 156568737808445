var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c(
        "legend",
        [
          _c("a-icon", { attrs: { type: "solution" } }),
          _vm._v(" " + _vm._s(_vm.$t("lbl_employee")) + " ")
        ],
        1
      ),
      _c(
        "a-form-model-item",
        {
          attrs: {
            label: _vm.$t("common.register-text", {
              text: _vm.$t("lbl_employee")
            }),
            prop: "general.isEmployee"
          }
        },
        [
          _c("a-switch", {
            attrs: { "data-testid": "contact-employee-is-employee" },
            model: {
              value: _vm.formState.general.isEmployee,
              callback: function($$v) {
                _vm.$set(_vm.formState.general, "isEmployee", $$v)
              },
              expression: "formState.general.isEmployee"
            }
          }),
          _vm._v(
            " " +
              _vm._s(
                _vm.formState.general.isEmployee
                  ? _vm.$t("lbl_yes")
                  : _vm.$t("lbl_no")
              ) +
              " "
          )
        ],
        1
      ),
      _vm.formState.general.isEmployee
        ? [
            _vm.formState.general.employee.employeeNumber
              ? _c(
                  "a-form-model-item",
                  {
                    attrs: {
                      label: _vm.$t("lbl_employee_number"),
                      prop: "general.employee.employeeNumber"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "ant-form-text",
                        attrs: {
                          "data-testid": "contact-employee-employee-number"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formState.general.employee.employeeNumber
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "a-form-model-item",
              {
                attrs: {
                  label: _vm.$t("lbl_nik"),
                  rules: {
                    required: _vm.formState.general.isEmployee,
                    message: function() {
                      return _vm.$t("lbl_validation_required_error")
                    }
                  },
                  prop: "general.employee.nik"
                }
              },
              [
                _c("a-input", {
                  attrs: {
                    "data-testid": "contact-employee-nik",
                    "allow-clear": "",
                    placeholder: _vm.$t("common.insert-text", {
                      text: _vm.$t("lbl_nik")
                    })
                  },
                  model: {
                    value: _vm.formState.general.employee.nik,
                    callback: function($$v) {
                      _vm.$set(_vm.formState.general.employee, "nik", $$v)
                    },
                    expression: "formState.general.employee.nik"
                  }
                })
              ],
              1
            ),
            _c(
              "a-form-model-item",
              {
                attrs: {
                  label: _vm.$t("lbl_gender"),
                  prop: "general.employee.gender"
                }
              },
              [
                _c("a-radio-group", {
                  attrs: {
                    name: "gender",
                    "data-testid": "contact-employee-gender",
                    options: _vm.genderOptions
                  },
                  model: {
                    value: _vm.formState.general.employee.gender,
                    callback: function($$v) {
                      _vm.$set(_vm.formState.general.employee, "gender", $$v)
                    },
                    expression: "formState.general.employee.gender"
                  }
                })
              ],
              1
            ),
            _c(
              "a-form-model-item",
              {
                attrs: {
                  label: _vm.$t("lbl_date_of_birth"),
                  rules: {
                    required: _vm.formState.general.isEmployee,
                    message: function() {
                      return _vm.$t("lbl_validation_required_error")
                    }
                  },
                  prop: "general.employee.dob"
                }
              },
              [
                _c("a-date-picker", {
                  attrs: {
                    format: _vm.DEFAULT_DATE_FORMAT,
                    "data-testid": "contact-employee-dob",
                    "allow-clear": ""
                  },
                  model: {
                    value: _vm.formState.general.employee.dob,
                    callback: function($$v) {
                      _vm.$set(_vm.formState.general.employee, "dob", $$v)
                    },
                    expression: "formState.general.employee.dob"
                  }
                })
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { gutter: [16, 16], type: "flex" } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_department"),
                          "wrapper-col": { span: 24 },
                          prop: "general.employee.department",
                          rules: {
                            required: _vm.formState.general.isEmployee,
                            message: function() {
                              return _vm.$t("lbl_validation_required_error")
                            }
                          }
                        }
                      },
                      [
                        _c("a-select", {
                          attrs: {
                            "allow-clear": "",
                            "data-testid": "contact-employee-department",
                            placeholder: _vm.$t("common.select-text", {
                              text: _vm.$t("lbl_department")
                            }),
                            options: _vm.departmentOptions,
                            "show-search": "",
                            loading: _vm.loading.department
                          },
                          model: {
                            value: _vm.formState.general.employee.department,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formState.general.employee,
                                "department",
                                $$v
                              )
                            },
                            expression: "formState.general.employee.department"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_start_date"),
                          "wrapper-col": { span: 24 },
                          rules: {
                            required: _vm.formState.general.isEmployee,
                            message: function() {
                              return _vm.$t("lbl_validation_required_error")
                            }
                          },
                          prop: "general.employee.startDate"
                        }
                      },
                      [
                        _c("a-date-picker", {
                          attrs: {
                            format: _vm.DEFAULT_DATE_FORMAT,
                            "allow-clear": "",
                            "data-testid": "contact-employee-start-date"
                          },
                          model: {
                            value: _vm.formState.general.employee.startDate,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formState.general.employee,
                                "startDate",
                                $$v
                              )
                            },
                            expression: "formState.general.employee.startDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }