





































































































import { generateUUID } from "@/helpers/uuid";
import {
  ContactFormAddressState,
  ContactFormState,
} from "@/store/models/Contact.model";
import { initContactFormAddressState } from "@/store/resources/Contact.resource";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import DisplayContactAddress from "./DisplayContactAddress.vue";
import FormContactManageAddress from "./FormContactManageAddress.vue";

export default Vue.extend({
  name: "FormContactAddresses",
  components: {
    FormContactManageAddress,
    DisplayContactAddress,
  },
  computed: {
    ...mapState({
      formState: (st: any) =>
        st.contactStore.contactFormState as ContactFormState,
    }),
    billingAddressList(): ContactFormAddressState[] {
      return this.formState.addresses.filter(item => item.asBill);
    },
    shipingAddressList(): ContactFormAddressState[] {
      return this.formState.addresses.filter(item => item.asShip);
    },
  },
  data() {
    return {
      isShowManage: false,
      dtManageAddress: undefined as ContactFormAddressState | undefined,
      isCreate: false,
    };
  },
  methods: {
    ...mapActions({
      deleteAddress: "contactStore/deleteAddress",
    }),
    toggleManage() {
      this.isShowManage = !this.isShowManage;
    },
    onCloseManageAddress() {
      this.toggleManage();
      this.dtManageAddress = undefined;
    },
    showManageAddress() {
      this.dtManageAddress = initContactFormAddressState();
      this.dtManageAddress.isPrimaryBill = !this.formState.addresses.find(
        item => item.isPrimaryBill
      );
      this.dtManageAddress.isPrimaryShip = !this.formState.addresses.find(
        item => item.isPrimaryShip
      );
      this.isCreate = true;
      this.toggleManage();
    },
    editAddress(
      address: ContactFormAddressState,
      payload: ContactFormAddressState
    ) {
      address.address = payload.address;
      address.city = payload.city;
      address.country = payload.country;
      address.isPrimaryBill = payload.isPrimaryBill;
      address.isPrimaryShip = payload.isPrimaryShip;
      address.location = payload.location;
      address.pics = payload.pics;
      address.postalCode = payload.postalCode;
      address.taxType = payload.taxType;
      address.asBill = payload.asBill;
      address.asShip = payload.asShip;
    },
    addAddress(payload: ContactFormAddressState) {
      if (payload.asBill && payload.asShip) {
        const bill: ContactFormAddressState = {
          ...payload,
          asShip: false,
          isPrimaryShip: false,
          key: generateUUID(),
          pics: payload.pics.map(pic => ({
            ...pic,
            key: generateUUID(),
          })),
        };
        const ship: ContactFormAddressState = {
          ...payload,
          asBill: false,
          isPrimaryBill: false,
          key: generateUUID(),
          pics: payload.pics.map(pic => ({
            ...pic,
            key: generateUUID(),
          })),
        };
        this.formState.addresses.push(bill, ship);
      } else {
        this.formState.addresses.push(payload);
      }
    },
    onSubmitManageAddress({ payload }: { payload: ContactFormAddressState }) {
      const address = this.formState.addresses.find(
        item => item.key === payload.key
      );
      if (address) {
        this.editAddress(address, payload);
      } else {
        this.addAddress(payload);
      }
      this.onCloseManageAddress();
    },
    handleEditAddress(address: ContactFormAddressState) {
      this.isCreate = false;
      this.dtManageAddress = { ...address };
      this.toggleManage();
    },
    handleDeleteAddress(address: ContactFormAddressState) {
      this.deleteAddress(address.key);
    },
  },
});
