





























































import { ContactFormAddressState } from "@/store/models/Contact.model";
import Vue from "vue";

export default Vue.extend({
  name: "DisplayContactAddress",
  props: {
    address: {
      type: Object as () => ContactFormAddressState,
      required: true,
    },
  },
  methods: {
    handleEdit() {
      this.$emit("edit");
    },
    handleDelete() {
      this.$emit("delete");
    },
    handleMenuClick({ key }: { key: "edit" | "delete" }) {
      if (key === "edit") {
        this.$emit("edit");
      } else if (key === "delete") {
        this.$emit("delete");
      }
    },
  },
});
