var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c(
        "legend",
        [
          _c("a-icon", { attrs: { type: "solution" } }),
          _vm._v(" " + _vm._s(_vm.$t("lbl_supplier")) + " ")
        ],
        1
      ),
      _c(
        "a-form-model-item",
        {
          attrs: {
            label: _vm.$t("common.register-text", {
              text: _vm.$t("lbl_supplier")
            }),
            prop: "general.isSupplier"
          }
        },
        [
          _c("a-switch", {
            attrs: { "data-testid": "contact-supplier-is-supplier" },
            model: {
              value: _vm.formState.general.isSupplier,
              callback: function($$v) {
                _vm.$set(_vm.formState.general, "isSupplier", $$v)
              },
              expression: "formState.general.isSupplier"
            }
          }),
          _vm._v(
            " " +
              _vm._s(
                _vm.formState.general.isSupplier
                  ? _vm.$t("lbl_yes")
                  : _vm.$t("lbl_no")
              ) +
              " "
          )
        ],
        1
      ),
      _vm.formState.general.isSupplier
        ? [
            _vm.formState.general.supplier.supplierNumber
              ? _c(
                  "a-form-model-item",
                  {
                    attrs: {
                      label: _vm.$t("lbl_supplier_number"),
                      prop: "general.supplier.supplierNumber"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "ant-form-text",
                        attrs: {
                          "data-testid": "contact-supplier-supplier-number"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formState.general.supplier.supplierNumber
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "a-form-model-item",
              {
                attrs: {
                  label: _vm.$t("lbl_supplier_type"),
                  prop: "general.supplier.type",
                  rules: {
                    required: _vm.formState.general.isSupplier,
                    message: function() {
                      return _vm.$t("lbl_validation_required_error")
                    }
                  }
                }
              },
              [
                _c("a-select", {
                  attrs: {
                    "data-testid": "contact-supplier-type",
                    placeholder: _vm.$t("common.select-text", {
                      text: _vm.$t("lbl_supplier_type")
                    }),
                    options: _vm.supplierTypeOptions,
                    loading: _vm.loading.type,
                    "show-search": ""
                  },
                  model: {
                    value: _vm.formState.general.supplier.type,
                    callback: function($$v) {
                      _vm.$set(_vm.formState.general.supplier, "type", $$v)
                    },
                    expression: "formState.general.supplier.type"
                  }
                })
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }