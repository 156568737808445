





















































import { useBlob, useInvoiceAR, useLocalFilter } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import {
  ParamsPrintInvoiceAr,
  PrintTitleRequestDto,
} from "@/models/interface/invoice.interface";
import { FormModel } from "ant-design-vue";
import printJS from "print-js";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";

@Component({
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
})
export default class InvoicePrintModal extends Mixins(MNotification) {
  @Prop({ default: false, type: Boolean, required: true })
  value!: boolean;

  @Prop({ default: "", type: String, required: true })
  docId!: string;

  @Ref() readonly formModel!: FormModel;

  formState = {
    selectedTitle: "INVOICE",
    useValueAs: [] as string[],
  };

  valueOptions = [
    { label: this.$t("lbl_gross_value"), value: "GROSS" },
    { label: this.$t("lbl_base_amount_dpp"), value: "TAX_BASE" },
  ];

  formRules = {
    selectedTitle: [
      { required: true, message: this.$t("notif_validation_error") },
    ],
    useValueAs: [
      { required: true, message: this.$t("notif_validation_error") },
    ],
  };

  searchStr = ""; // state to save new item if doesnt exist

  useLocalFilter = useLocalFilter;

  loading = {
    print: false,
    title: false,
  };

  options: Option[] = [];

  created(): void {
    this.fetchPrintTitle();
  }

  close(): void {
    this.$emit("input", !this.value);
  }

  buildParams(state: {
    selectedTitle: string;
    useValueAs: string[];
  }): ParamsPrintInvoiceAr {
    let type!: "GROSS" | "TAX_BASE" | "ALL";
    if (state.useValueAs.length === 2) {
      type = "ALL";
    } else if (state.useValueAs.includes("GROSS")) {
      type = "GROSS";
    } else if (state.useValueAs.includes("TAX_BASE")) {
      type = "TAX_BASE";
    }

    return {
      type,
      title: state.selectedTitle,
    };
  }

  async validate(): Promise<void> {
    try {
      await this.formModel.validate();
      this.handlePrint(this.docId, this.formState);
    } catch {
      this.showNotifWarning("notif_validation_error");
    }
  }

  async handlePrint(
    docId: string,
    state: { selectedTitle: string; useValueAs: string[] }
  ): Promise<void> {
    const { toObjectUrl } = useBlob();
    const { print } = useInvoiceAR();
    const params = this.buildParams(state);
    try {
      this.loading.print = true;
      const res = await print(docId, params);
      printJS(toObjectUrl(res));
    } catch {
      this.showNotifError("notif_print_fail");
    } finally {
      this.loading.print = false;
    }
  }

  fetchPrintTitle(): void {
    const { findAllPrintTitle } = useInvoiceAR();
    this.loading.title = true;
    findAllPrintTitle()
      .then(res => {
        this.options = res.map(item => ({
          label: item.name,
          value: item.name,
          key: item.id,
        }));
      })
      .finally(() => {
        this.loading.title = false;
      });
  }

  addItem(): void {
    const { addPrintTitle } = useInvoiceAR();
    const payload: PrintTitleRequestDto = {
      name: this.searchStr,
    };
    this.loading.title = true;
    addPrintTitle(payload)
      .then(() => {
        this.fetchPrintTitle();
      })
      .finally(() => {
        this.loading.title = false;
      });
  }

  onSearch(e: string | null): void {
    this.searchStr = e || "";
  }
}
