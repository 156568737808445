






















































































































import { useFindMasterType, useMapMasterTypeToOptionAlt } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { ContactFormState } from "@/store/models/Contact.model";
import Vue from "vue";
import { mapState } from "vuex";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";

export default Vue.extend({
  name: "FormContactEmployee",
  computed: {
    ...mapState({
      formState: (st: any) =>
        st.contactStore.contactFormState as ContactFormState,
    }),
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      genderOptions: [
        {
          label: this.$t("lbl_male"),
          value: "Male",
        },
        {
          label: this.$t("lbl_female"),
          value: "Female",
        },
      ],
      departmentOptions: [] as Option[],
      loading: {
        department: false,
      },
    };
  },
  mounted() {
    this.fetchDepartmentList();
  },
  methods: {
    async fetchDepartmentList() {
      try {
        this.loading.department = true;
        const response = await useFindMasterType("DEPARTMENT");
        this.departmentOptions = useMapMasterTypeToOptionAlt(response);
      } finally {
        this.loading.department = false;
      }
    },
  },
});
