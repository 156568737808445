var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c(
        "legend",
        [
          _c("a-icon", { attrs: { type: "book" } }),
          _vm._v(" " + _vm._s(_vm.$t("lbl_account")) + " ")
        ],
        1
      ),
      _vm._l(_vm.fields, function(field) {
        return _c(
          "a-row",
          { key: field.prop, attrs: { gutter: [16, 16], type: "flex" } },
          [
            _c(
              "a-col",
              { attrs: { span: 24 } },
              [
                _c(
                  "a-form-model-item",
                  {
                    attrs: {
                      label: field.label,
                      prop: "account." + field.prop,
                      "wrapper-col": { span: 12 },
                      "label-col": { span: 24 }
                    }
                  },
                  [
                    _c("a-select", {
                      staticClass: "w-100",
                      attrs: {
                        placeholder: field.placeholder,
                        "show-search": "",
                        "label-in-value": "",
                        loading: field.loading,
                        "filter-option": false,
                        options: field.isSearch
                          ? field.options
                          : _vm.accountOptions,
                        "data-testid": "contact-account-" + field.prop
                      },
                      on: {
                        search: function($event) {
                          return _vm.onSearch(field, $event)
                        }
                      },
                      model: {
                        value: _vm.formState.account[field.prop],
                        callback: function($$v) {
                          _vm.$set(_vm.formState.account, field.prop, $$v)
                        },
                        expression: "formState.account[field.prop]"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }