var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-form-model",
        {
          ref: "refFormContact",
          attrs: {
            model: _vm.formState,
            rules: _vm.formRules,
            layout: "vertical",
            "label-col": { span: 24 },
            "wrapper-col": { span: 12 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-tabs",
            {
              model: {
                value: _vm.currentTab,
                callback: function($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab"
              }
            },
            _vm._l(_vm.tabs, function(tab) {
              return _c(
                "a-tab-pane",
                {
                  key: tab.key,
                  attrs: {
                    "data-testid": "contact-tab-" + tab.key,
                    "force-render": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "tab",
                        fn: function() {
                          return [
                            _c(
                              "span",
                              [
                                _c("a-icon", { attrs: { type: tab.icon } }),
                                _vm._v(" " + _vm._s(_vm.$t(tab.title)) + " ")
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                [_c(tab.component, { tag: "component" })],
                1
              )
            }),
            1
          ),
          _c(
            "a-row",
            { staticClass: "mt-2", attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { "data-testid": "contact-cancel" },
                          on: { click: _vm.handleCancel }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                      ),
                      _vm.$can("create", "contact-data") ||
                      _vm.$can("update", "contact-data")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading,
                                "html-type": "submit",
                                "data-testid": "contact-submit"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isEdit
                                      ? _vm.$t("common.edit-text", {
                                          text: _vm.$t("lbl_contact")
                                        })
                                      : _vm.$t("common.create-text", {
                                          text: _vm.$t("lbl_contact")
                                        })
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }