














































import { SearchBuilder } from "@/builder";
import { DisplayBoolean } from "@/components/DisplayBoolean";
import { debounceProcess } from "@/helpers/debounce";
import { useContactData } from "@/hooks";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { EnumContactType } from "@/models/enums/Contact.enum";
import { ListContactDataDto } from "@/models/interface/contact-data";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ColumnDef } from "@/types";
import Vue from "vue";

export default Vue.extend({
  name: "IndexCustomer",
  props: {
    type: {
      type: String,
      required: true,
    },
    search: {
      type: String,
      required: false,
    },
  },
  components: {
    DisplayBoolean,
  },
  computed: {
    getContactNumberTitle() {
      if (this.type === "customer") {
        return this.$t("lbl_customer_number");
      } else if (this.type === "supplier") {
        return this.$t("lbl_supplier_number");
      } else if (this.type === "employee") {
        return this.$t("lbl_employee_number");
      }
      return "";
    },
  },
  data() {
    this.onChangeSearch = debounceProcess(this.onChangeSearch, 500);
    return {
      PAGE_SIZE_OPTIONS,
      columns: [
        {
          key: "contactNumber",
          scopedSlots: { customRender: "contactNumber" },
          slots: { title: "contactNumberTitle" },
        },
        {
          title: this.$t("lbl_full_name"),
          dataIndex: "fullName",
          key: "fullName",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_phone_number"),
          dataIndex: "phoneNumber",
          key: "phoneNumber",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_email"),
          dataIndex: "email",
          key: "email",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "active",
          key: "status",
          align: "center",
          scopedSlots: { customRender: "boolean" },
        },
        {
          title: this.$t("lbl_action"),
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 120,
          align: "center",
        },
      ] as ColumnDef[],
      contacts: [] as ListContactDataDto[],
      loading: false,
      pagination: {
        total: 0,
        page: 1,
        limit: 10,
      },
    };
  },
  watch: {
    type: {
      immediate: true,
      handler: "onChangeType",
    },
    search: {
      handler: "onChangeSearch",
    },
  },
  methods: {
    renderCustomerNumber(record: ListContactDataDto) {
      if (this.type === "customer") {
        return record.customerNumber ?? "-";
      } else if (this.type === "supplier") {
        return record.supplierNumber ?? "-";
      } else if (this.type === "employee") {
        return record.employeeNumber ?? "-";
      }
      return "";
    },
    onChangeTable(pagination: { current: number; pageSize: number }): void {
      const { current, pageSize } = pagination;
      this.pagination.page =
        pageSize !== this.pagination.limit ? DEFAULT_PAGE : current;
      this.pagination.limit = pageSize;

      const params = new RequestQueryParams();
      params.limit = this.pagination.limit;
      params.page = this.pagination.page - 1;
      params.search = this.buildSearch(
        this.type as EnumContactType,
        this.search
      );
      this.fetchContacts(params);
    },
    async fetchContacts(params?: RequestQueryParamsModel) {
      const { findAll } = useContactData();
      try {
        this.loading = true;
        const response = await findAll(params);
        this.contacts = response.data;
        this.pagination.total = response.totalElements;
        this.$emit("done", response);
      } finally {
        this.loading = false;
      }
    },
    buildSearch(type: EnumContactType, search?: string): string {
      const builder = new SearchBuilder();

      if (!search) {
        return builder.push([type, "true"]).build();
      }

      const contactNumber = `${type}Number`;

      return builder
        .push(["firstName", search], { like: "both" })
        .or()
        .push(["lastName", search], { like: "both" })
        .or()
        .push(["phoneNumber", search], { like: "both" })
        .or()
        .push(["email", search], { like: "both" })
        .or()
        .push([contactNumber, search], { like: "both" })
        .and()
        .push([type, "true"])
        .build();
    },
    onChangeType(type: EnumContactType) {
      const params = new RequestQueryParams();
      params.search = this.buildSearch(type, this.search);
      this.fetchContacts(params);
    },
    onChangeSearch(search?: string) {
      const params = new RequestQueryParams();
      params.search = this.buildSearch(this.type as EnumContactType, search);
      this.fetchContacts(params);

      this.$router.replace({
        name: "settings.contact.list",
        query: {
          ...this.$route.query,
          q: search || undefined,
        },
      });
    },
  },
});
