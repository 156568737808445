var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c(
        "legend",
        [
          _c("a-icon", { attrs: { type: "bank" } }),
          _vm._v(" " + _vm._s(_vm.$t("lbl_bank")) + " ")
        ],
        1
      ),
      _vm.formState.banks.length === 0
        ? _c(
            "a-empty",
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    "data-testid": "contact-bank-insert"
                  },
                  on: { click: _vm.insertBank }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("common.insert-text", {
                          text: _vm.$t("lbl_bank")
                        })
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        : [
            _c(
              "a-button",
              {
                attrs: {
                  "data-testid": "contact-bank-insert",
                  type: "primary",
                  size: "small"
                },
                on: { click: _vm.insertBank }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("common.insert-text", { text: _vm.$t("lbl_bank") })
                    ) +
                    " "
                )
              ]
            ),
            _c("a-list", {
              staticClass: "mt-2",
              attrs: {
                "item-layout": "vertical",
                "data-source": _vm.formState.banks,
                bordered: true,
                "data-testid": "contact-bank-list"
              },
              scopedSlots: _vm._u([
                {
                  key: "renderItem",
                  fn: function(item, index) {
                    return [
                      _c(
                        "a-list-item",
                        {
                          attrs: { "data-testid": "contact-bank-list-item" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "actions",
                                fn: function() {
                                  return [
                                    _c(
                                      "a-space",
                                      [
                                        !item.isEdit
                                          ? _c(
                                              "a-button",
                                              {
                                                attrs: {
                                                  size: "small",
                                                  "data-testid":
                                                    "contact-bank-edit-" + index
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editBank(item)
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("lbl_edit")) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "a-button",
                                              {
                                                attrs: {
                                                  size: "small",
                                                  type: "primary",
                                                  "data-testid":
                                                    "contact-bank-save-" + index
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.saveBank(item)
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("lbl_save")) +
                                                    " "
                                                )
                                              ]
                                            ),
                                        _c(
                                          "a-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              "data-testid":
                                                "contact-bank-delete-name-" +
                                                index
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteBank(item)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("lbl_delete")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          item.isEdit
                            ? [
                                _c(
                                  "a-row",
                                  { attrs: { type: "flex", gutter: [16, 16] } },
                                  [
                                    _c(
                                      "a-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lbl_bank_account_name"
                                              ),
                                              prop: "accountName"
                                            }
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                "allow-clear": "",
                                                "data-testid":
                                                  "contact-bank-account-name-" +
                                                  index,
                                                placeholder: _vm.$t(
                                                  "common.insert-text",
                                                  {
                                                    text: _vm.$t(
                                                      "lbl_bank_account_name"
                                                    )
                                                  }
                                                )
                                              },
                                              model: {
                                                value: item.accountName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "accountName",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.accountName"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lbl_bank_account_number"
                                              ),
                                              prop: "accountNumber"
                                            }
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                "allow-clear": "",
                                                "data-testid":
                                                  "contact-bank-account-number-" +
                                                  index,
                                                placeholder: _vm.$t(
                                                  "common.insert-text",
                                                  {
                                                    text: _vm.$t(
                                                      "lbl_bank_account_number"
                                                    )
                                                  }
                                                )
                                              },
                                              model: {
                                                value: item.accountNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "accountNumber",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.accountNumber"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-row",
                                  { attrs: { type: "flex", gutter: [16, 16] } },
                                  [
                                    _c(
                                      "a-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label: _vm.$t("lbl_bank_name"),
                                              prop: "bankName"
                                            }
                                          },
                                          [
                                            _c("a-select", {
                                              staticClass: "w-100",
                                              attrs: {
                                                "allow-clear": "",
                                                placeholder: _vm.$t(
                                                  "common.select-text",
                                                  {
                                                    text: _vm.$t(
                                                      "lbl_bank_name"
                                                    )
                                                  }
                                                ),
                                                "data-testid":
                                                  "contact-bank-bank-name-" +
                                                  index,
                                                options: _vm.bankOptions,
                                                "filter-option": false,
                                                "show-search": ""
                                              },
                                              on: { search: _vm.onSearchBank },
                                              model: {
                                                value: item.bankName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "bankName",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.bankName"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lbl_bank_branch_name"
                                              ),
                                              prop: "branchName"
                                            }
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                "data-testid":
                                                  "contact-bank-branch-name-" +
                                                  index,
                                                "allow-clear": "",
                                                placeholder: _vm.$t(
                                                  "common.insert-text",
                                                  {
                                                    text: _vm.$t(
                                                      "lbl_bank_branch_name"
                                                    )
                                                  }
                                                )
                                              },
                                              model: {
                                                value: item.branchName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "branchName",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.branchName"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : [
                                _c("a-list-item-meta", {
                                  attrs: {
                                    description:
                                      item.bankName + " - " + item.branchName
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function() {
                                          return [
                                            _c("a-icon", {
                                              attrs: { type: "bank" }
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.accountName +
                                                    " - " +
                                                    item.accountNumber
                                                ) +
                                                " "
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ]
                        ],
                        2
                      )
                    ]
                  }
                }
              ])
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }