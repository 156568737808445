var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "fieldset",
        [
          _c(
            "legend",
            [
              _c("a-icon", { attrs: { type: "user" } }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("common.information-text", {
                      text: _vm.$t("lbl_general")
                    })
                  ) +
                  " "
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_first_name"),
                        "wrapper-col": { span: 24 },
                        prop: "general.firstName"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "allow-clear": "",
                          "data-testid": "contact-general-firstname",
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_first_name")
                          })
                        },
                        model: {
                          value: _vm.formState.general.firstName,
                          callback: function($$v) {
                            _vm.$set(_vm.formState.general, "firstName", $$v)
                          },
                          expression: "formState.general.firstName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_last_name"),
                        "wrapper-col": { span: 24 },
                        prop: "general.lastName"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "data-testid": "contact-general-lastname",
                          "allow-clear": "",
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_last_name")
                          })
                        },
                        model: {
                          value: _vm.formState.general.lastName,
                          callback: function($$v) {
                            _vm.$set(_vm.formState.general, "lastName", $$v)
                          },
                          expression: "formState.general.lastName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_email"), prop: "general.email" } },
            [
              _c("a-input", {
                attrs: {
                  "allow-clear": "",
                  "data-testid": "contact-general-email",
                  placeholder: _vm.$t("common.insert-text", {
                    text: _vm.$t("lbl_email")
                  })
                },
                model: {
                  value: _vm.formState.general.email,
                  callback: function($$v) {
                    _vm.$set(_vm.formState.general, "email", $$v)
                  },
                  expression: "formState.general.email"
                }
              })
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_phone_number"),
                        "wrapper-col": { span: 24 },
                        prop: "general.phoneNumber"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "allow-clear": "",
                          "data-testid": "contact-general-phone-number",
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_phone_number")
                          })
                        },
                        model: {
                          value: _vm.formState.general.phoneNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.formState.general, "phoneNumber", $$v)
                          },
                          expression: "formState.general.phoneNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_mobile_number"),
                        "wrapper-col": { span: 24 },
                        prop: "general.mobileNumber"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "data-testid": "contact-general-mobile-number",
                          "allow-clear": "",
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_mobile_number")
                          })
                        },
                        model: {
                          value: _vm.formState.general.mobileNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.formState.general, "mobileNumber", $$v)
                          },
                          expression: "formState.general.mobileNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_fax_number"),
                prop: "general.faxNumber"
              }
            },
            [
              _c("a-input", {
                attrs: {
                  "data-testid": "contact-general-fax-number",
                  "allow-clear": "",
                  placeholder: _vm.$t("common.insert-text", {
                    text: _vm.$t("lbl_fax_number")
                  })
                },
                model: {
                  value: _vm.formState.general.faxNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.formState.general, "faxNumber", $$v)
                  },
                  expression: "formState.general.faxNumber"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_term_of_payments"),
                prop: "general.termOfPayment"
              }
            },
            [
              _c("a-select", {
                attrs: {
                  "allow-clear": "",
                  placeholder: _vm.$t("common.select-text", {
                    text: _vm.$t("lbl_term_of_payments")
                  }),
                  options: _vm.topOptions,
                  "data-testid": "contact-general-term-of-payment"
                },
                model: {
                  value: _vm.formState.general.termOfPayment,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.formState.general,
                      "termOfPayment",
                      _vm._n($$v)
                    )
                  },
                  expression: "formState.general.termOfPayment"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: _vm.$t("lbl_status"), prop: "general.isActive" }
            },
            [
              _c("a-switch", {
                attrs: { "data-testid": "contact-general-is-active" },
                model: {
                  value: _vm.formState.general.isActive,
                  callback: function($$v) {
                    _vm.$set(_vm.formState.general, "isActive", $$v)
                  },
                  expression: "formState.general.isActive"
                }
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.formState.general.isActive
                      ? _vm.$t("lbl_active")
                      : _vm.$t("lbl_inactive")
                  ) +
                  " "
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fieldset",
        [
          _c(
            "legend",
            [
              _c("a-icon", { attrs: { type: "audit" } }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("common.information-text", {
                      text: _vm.$t("lbl_tax")
                    })
                  ) +
                  " "
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_tax_registration_number"),
                prop: "general.taxRegistrationNumber"
              }
            },
            [
              _c("a-input", {
                attrs: {
                  "data-testid": "contact-general-tax-registration-number",
                  "allow-clear": "",
                  placeholder: _vm.$t("common.insert-text", {
                    text: _vm.$t("lbl_tax_registration_number")
                  })
                },
                model: {
                  value: _vm.formState.general.taxRegistrationNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.formState.general,
                      "taxRegistrationNumber",
                      $$v
                    )
                  },
                  expression: "formState.general.taxRegistrationNumber"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_tax_registration_name"),
                prop: "general.taxRegistrationName"
              }
            },
            [
              _c("a-input", {
                attrs: {
                  "data-testid": "contact-general-tax-registration-name",
                  "allow-clear": "",
                  placeholder: _vm.$t("common.insert-text", {
                    text: _vm.$t("lbl_tax_registration_name")
                  })
                },
                model: {
                  value: _vm.formState.general.taxRegistrationName,
                  callback: function($$v) {
                    _vm.$set(_vm.formState.general, "taxRegistrationName", $$v)
                  },
                  expression: "formState.general.taxRegistrationName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("FormContactCustomer"),
      _c("FormContactSupplier"),
      _c("FormContactEmployee")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }