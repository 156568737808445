























































import {
  FormContactAccount,
  FormContactAddresses,
  FormContactBank,
  FormContactGeneral,
} from "@/components/ContactData";
import { useContactData } from "@/hooks";
import { ContactMapper } from "@/mapper/Contact.mapper";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ContactDataCreateDto } from "@/models/interface/contact-data";
import { ContactFormState } from "@/store/models/Contact.model";
import { FormModel } from "ant-design-vue";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "FormContact",
  components: {
    FormContactGeneral,
    FormContactAddresses,
    FormContactAccount,
    FormContactBank,
  },
  props: {
    contactId: String,
  },
  mixins: [MNotificationVue],
  computed: {
    ...mapState({
      formState: (st: any) =>
        st.contactStore.contactFormState as ContactFormState,
    }),
    isEdit() {
      return !!this.contactId;
    },
  },
  data() {
    return {
      currentTab: "general",
      tabs: [
        {
          key: "general",
          title: "lbl_general",
          icon: "user",
          component: FormContactGeneral,
        },
        {
          key: "address",
          title: "lbl_address",
          icon: "home",
          component: FormContactAddresses,
        },
        {
          key: "bank",
          title: "lbl_bank",
          icon: "bank",
          component: FormContactBank,
        },
        {
          key: "account",
          title: "lbl_account",
          icon: "book",
          component: FormContactAccount,
        },
      ],
      formRules: {
        "general.firstName": [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        "general.taxRegistrationName": [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        "general.taxRegistrationNumber": [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        "account.receivable": [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        "account.revenue": [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        "account.payable": [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        "account.prepayment": [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
      },
      form: null as FormModel | null,
      loading: false,
    };
  },
  mounted() {
    if (this.$refs.refFormContact) {
      this.form = this.$refs.refFormContact as FormModel;
    }
  },
  methods: {
    async handleCreate() {
      const { create } = useContactData();
      const dto: ContactDataCreateDto = ContactMapper.toContactDataCreateDto(
        this.formState
      );
      try {
        this.loading = true;
        const response = await create(dto);
        this.showNotifSuccess("notif_create_success", {
          documentNumber: response.firstName,
        });
        this.$router.push({
          name: "settings.contact.list",
          query: this.$route.query,
        });
      } finally {
        this.loading = false;
      }
    },
    async handleUpdate(contactId: string) {
      const { update } = useContactData();
      const dto: ContactDataCreateDto = ContactMapper.toContactDataCreateDto(
        this.formState
      );
      try {
        this.loading = true;
        const response = await update(contactId, dto);
        this.showNotifSuccess("notif_update_success", {
          documentNumber: response.firstName,
        });
        this.$router.push({
          name: "settings.contact.list",
          query: this.$route.query,
        });
      } finally {
        this.loading = false;
      }
    },
    validateAddress() {
      return this.formState.addresses.length !== 0;
    },
    async handleSubmit() {
      try {
        await this.form?.validate();
        const isValid = this.validateAddress();
        if (!isValid) {
          throw new Error("Address is empty");
        }

        if (this.contactId) {
          this.handleUpdate(this.contactId);
        } else {
          this.handleCreate();
        }
      } catch (error) {
        this.showNotifError("notif_validation_error");
      }
    },
    handleCancel() {
      this.showConfirmationLeavePage(() => {
        this.$router.go(-1);
      });
    },
  },
});
