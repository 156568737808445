import DisplayContactAddress from "./DisplayContactAddress.vue";
import FormContact from "./FormContact.vue";
import FormContactAccount from "./FormContactAccount.vue";
import FormContactAddresses from "./FormContactAddresses.vue";
import FormContactBank from "./FormContactBank.vue";
import FormContactCustomer from "./FormContactCustomer.vue";
import FormContactEmployee from "./FormContactEmployee.vue";
import FormContactGeneral from "./FormContactGeneral.vue";
import FormContactManageAddress from "./FormContactManageAddress.vue";
import FormContactSupplier from "./FormContactSupplier.vue";
import IndexContact from "./IndexContact.vue";
import SelectContactAddress from "./SelectContactAddress.vue";

export {
  FormContactCustomer,
  FormContact,
  SelectContactAddress,
  IndexContact,
  FormContactGeneral,
  FormContactAccount,
  FormContactAddresses,
  FormContactBank,
  FormContactSupplier,
  FormContactEmployee,
  FormContactManageAddress,
  DisplayContactAddress,
};
