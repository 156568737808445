













































































































































import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import {
  useCompany,
  useFindMasterType,
  useMapMasterTypeToOptionAlt,
  useTaxInvoice,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Mode } from "@/models/enums/global.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ContactFormState } from "@/store/models/Contact.model";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "FormContactCustomer",
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
      formState: (st: any) =>
        st.contactStore.contactFormState as ContactFormState,
    }),
    isCreate() {
      return this.$route.meta.mode === Mode.CREATE;
    },
  },
  data() {
    this.onSearchTaxInvCode = debounceProcess(this.onSearchTaxInvCode, 500);
    this.onSearchCompany = debounceProcess(this.onSearchCompany, 500);
    return {
      customerTypeOptions: [] as Option[],
      taxInvoiceCodeOptions: [] as Option[],
      thirdAppOptions: [] as Option[],
      companyOptions: [] as Option[],
      loading: {
        type: false,
        taxInvCode: false,
        sync: false,
        copy: false,
      },
    };
  },
  mounted() {
    this.fetchCustomerTypeList();
    this.fetchTaxInvoiceCodeList({ search: "revisionCode~false" });
    this.fetchThirdAppList();
    this.fetchSiblingCompanyList();
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    onSearchCompany(value?: string) {
      const params = new RequestQueryParams();
      if (value) {
        params.search = new SearchBuilder()
          .push(["name", value], { like: "both" })
          .build();
      }

      this.fetchSiblingCompanyList(params);
    },
    async fetchSiblingCompanyList(params?: RequestQueryParamsModel) {
      const { findSiblings } = useCompany();
      try {
        this.loading.copy = true;
        const response = await findSiblings(params);
        this.companyOptions = response.data.map(item => ({
          key: item.id,
          label: item.name,
          value: item.id,
        }));
      } finally {
        this.loading.copy = false;
      }
    },
    async fetchThirdAppList() {
      try {
        this.loading.sync = true;
        const response = await useFindMasterType("THIRD_APPS");
        this.thirdAppOptions = response.map(item => ({
          key: item.id,
          value: item.id,
          label: item.value,
        }));
      } finally {
        this.loading.sync = false;
      }
    },
    async fetchCustomerTypeList() {
      try {
        this.loading.type = true;
        const response = await useFindMasterType("CUSTOMER_TYPE");
        this.customerTypeOptions = useMapMasterTypeToOptionAlt(response);
      } finally {
        this.loading.type = false;
      }
    },
    async fetchTaxInvoiceCodeList(params?: RequestQueryParamsModel) {
      const { findAll, toOptions } = useTaxInvoice();
      try {
        this.loading.taxInvCode = true;
        const response = await findAll(params);
        this.taxInvoiceCodeOptions = toOptions(response.data);
      } finally {
        this.loading.taxInvCode = false;
      }
    },
    onSearchTaxInvCode(value?: string) {
      const { findAllBy } = useTaxInvoice();
      const params = new RequestQueryParams();
      params.search = findAllBy({ code: value, revisionCode: "false" }).filter;
      this.fetchTaxInvoiceCodeList(params);
    },
  },
});
