var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c(
        "legend",
        [
          _c("a-icon", { attrs: { type: "solution" } }),
          _vm._v(" " + _vm._s(_vm.$t("lbl_customer")) + " ")
        ],
        1
      ),
      _c(
        "a-form-model-item",
        {
          attrs: {
            label: _vm.$t("common.register-text", {
              text: _vm.$t("lbl_customer")
            }),
            prop: "general.customer.isCustomer"
          }
        },
        [
          _c("a-switch", {
            attrs: { "data-testid": "contact-customer-is-customer" },
            model: {
              value: _vm.formState.general.isCustomer,
              callback: function($$v) {
                _vm.$set(_vm.formState.general, "isCustomer", $$v)
              },
              expression: "formState.general.isCustomer"
            }
          }),
          _vm._v(
            " " +
              _vm._s(
                _vm.formState.general.isCustomer
                  ? _vm.$t("lbl_yes")
                  : _vm.$t("lbl_no")
              ) +
              " "
          )
        ],
        1
      ),
      _vm.formState.general.isCustomer
        ? [
            _vm.formState.general.customer.customerNumber
              ? _c(
                  "a-form-model-item",
                  {
                    attrs: {
                      label: _vm.$t("lbl_customer_number"),
                      prop: "general.customer.customerNumber"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "ant-form-text",
                        attrs: {
                          "data-testid": "contact-customer-customer-number"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formState.general.customer.customerNumber
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "a-form-model-item",
              {
                attrs: {
                  label: _vm.$t("lbl_customer_type"),
                  rules: {
                    required: _vm.formState.general.isCustomer,
                    message: function() {
                      return _vm.$t("lbl_validation_required_error")
                    }
                  },
                  prop: "general.customer.type"
                }
              },
              [
                _c("a-select", {
                  attrs: {
                    "data-testid": "contact-customer-type",
                    placeholder: _vm.$t("common.select-text", {
                      text: _vm.$t("lbl_customer_type")
                    }),
                    options: _vm.customerTypeOptions,
                    loading: _vm.loading.type
                  },
                  model: {
                    value: _vm.formState.general.customer.type,
                    callback: function($$v) {
                      _vm.$set(_vm.formState.general.customer, "type", $$v)
                    },
                    expression: "formState.general.customer.type"
                  }
                })
              ],
              1
            ),
            _c(
              "a-form-model-item",
              {
                attrs: {
                  label: _vm.$t("lbl_tax_invoice_code"),
                  prop: "general.customer.taxInvoiceCode"
                }
              },
              [
                _c("a-select", {
                  attrs: {
                    "allow-clear": "",
                    "data-testid": "contact-customer-tax-invoice-code",
                    placeholder: _vm.$t("common.select-text", {
                      text: _vm.$t("lbl_tax_invoice_code")
                    }),
                    options: _vm.taxInvoiceCodeOptions,
                    "filter-option": false,
                    loading: _vm.loading.type,
                    "show-search": ""
                  },
                  on: { search: _vm.onSearchTaxInvCode },
                  model: {
                    value: _vm.formState.general.customer.taxInvoiceCode,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.formState.general.customer,
                        "taxInvoiceCode",
                        $$v
                      )
                    },
                    expression: "formState.general.customer.taxInvoiceCode"
                  }
                })
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { type: "flex" } },
              [
                _c(
                  "a-col",
                  [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_credit_limit"),
                          prop: "general.customer.creditLimit"
                        }
                      },
                      [
                        _c("a-input-number", {
                          staticClass: "w-100",
                          attrs: {
                            min: 0,
                            parser: _vm.reverseFormatNumber,
                            formatter: _vm.formatterNumber,
                            precision: _vm.storeBaseDecimalPlace,
                            "data-testid": "contact-customer-credit-limit"
                          },
                          model: {
                            value: _vm.formState.general.customer.creditLimit,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formState.general.customer,
                                "creditLimit",
                                $$v
                              )
                            },
                            expression: "formState.general.customer.creditLimit"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_discount"),
                          prop: "general.customer.discount"
                        }
                      },
                      [
                        _c("a-input-number", {
                          staticClass: "w-100",
                          attrs: {
                            min: 0,
                            parser: _vm.reverseFormatNumber,
                            formatter: _vm.formatterNumber,
                            precision: _vm.storeBaseDecimalPlace,
                            "data-testid": "contact-customer-discount"
                          },
                          model: {
                            value: _vm.formState.general.customer.discount,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formState.general.customer,
                                "discount",
                                $$v
                              )
                            },
                            expression: "formState.general.customer.discount"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_synchronize_with"),
                          props: "general.customer.thirdApps"
                        }
                      },
                      [
                        _c("a-select", {
                          attrs: {
                            mode: "multiple",
                            "allow-clear": "",
                            options: _vm.thirdAppOptions,
                            placeholder: _vm.$t("lbl_select_here"),
                            "data-testid": "contact-customer-sync"
                          },
                          model: {
                            value: _vm.formState.general.customer.thirdApps,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formState.general.customer,
                                "thirdApps",
                                $$v
                              )
                            },
                            expression: "formState.general.customer.thirdApps"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.isCreate
                  ? _c(
                      "a-col",
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_clone"),
                              props: "general.customer.companyClones"
                            }
                          },
                          [
                            _c("a-select", {
                              attrs: {
                                "allow-clear": "",
                                "show-search": "",
                                "label-in-value": "",
                                mode: "multiple",
                                "filter-option": false,
                                options: _vm.companyOptions,
                                placeholder: _vm.$t("lbl_select_here"),
                                "data-testid": "contact-customer-clone"
                              },
                              on: { search: _vm.onSearchCompany },
                              model: {
                                value:
                                  _vm.formState.general.customer.companyClones,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formState.general.customer,
                                    "companyClones",
                                    $$v
                                  )
                                },
                                expression:
                                  "formState.general.customer.companyClones"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }