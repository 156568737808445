var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      "row-key": "id",
      "data-source": _vm.contacts,
      columns: _vm.columns,
      size: "small",
      loading: _vm.loading,
      pagination: {
        showTotal: function() {
          return _vm.$t("lbl_total_items", { total: _vm.pagination.total })
        },
        showSizeChanger: true,
        pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
        total: _vm.pagination.total,
        current: _vm.pagination.page,
        defaultPageSize: _vm.pagination.limit
      },
      "data-testid": "contact-table",
      "row-class-name": function(_, index) {
        return index % 2 ? "bg-white" : "bg-gray-light"
      }
    },
    on: { change: _vm.onChangeTable },
    scopedSlots: _vm._u([
      {
        key: "contactNumberTitle",
        fn: function() {
          return [_c("span", [_vm._v(_vm._s(_vm.getContactNumberTitle))])]
        },
        proxy: true
      },
      {
        key: "contactNumber",
        fn: function(text, record) {
          return [
            _c("span", { attrs: { "data-testid": "contact-number" } }, [
              _vm._v(" " + _vm._s(_vm.renderCustomerNumber(record)) + " ")
            ])
          ]
        }
      },
      {
        key: "boolean",
        fn: function(text, record) {
          return [_c("DisplayBoolean", { attrs: { value: record.active } })]
        }
      },
      {
        key: "action",
        fn: function(text, record) {
          return [
            _c(
              "RouterLink",
              {
                attrs: {
                  to: {
                    name: "settings.contact.edit",
                    params: { id: record.id },
                    query: _vm.$route.query
                  },
                  "data-testid": "contact-view"
                }
              },
              [
                _c("a-button", { attrs: { type: "link" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_view")) + " ")
                ])
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }