












































































































































































































































































































































































import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useFindMasterType } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@/services/contact.service";
import { ContactFormAddressState } from "@/store/models/Contact.model";
import {
  initContactFormAddressState,
  initContactFormPicState,
} from "@/store/resources/Contact.resource";
import { FormModel } from "ant-design-vue";
import Vue from "vue";

export default Vue.extend({
  name: "FormContactManageAddress",
  props: {
    address: {
      type: Object as () => ContactFormAddressState | undefined,
      required: false,
    },
    isCreate: {
      type: Boolean,
    },
    addresses: {
      type: Array as () => ContactFormAddressState[],
      required: false,
      default: [],
    },
  },
  mixins: [MNotificationVue],
  data() {
    this.onSearchCity = debounceProcess(this.onSearchCity, 500);
    this.onSearchPostalCode = debounceProcess(this.onSearchPostalCode, 500);
    return {
      formState: initContactFormAddressState() as ContactFormAddressState,
      cityOptions: [] as Option[],
      postalCodeOptions: [] as Option[],
      taxTypeOptions: [] as Option[],
      loading: {
        city: false,
        postal: false,
        taxType: false,
      },
      form: null as FormModel | null,
      formRules: {
        city: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        postalCode: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        address: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        location: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
      },
      isEdit: false,
    };
  },
  computed: {
    hasPrimaryShiping() {
      return !!this.addresses.find(
        item => item.isPrimaryShip && this.address?.key !== item.key
      );
    },
    hasPrimaryBilling() {
      return !!this.addresses.find(
        item => item.isPrimaryBill && this.address?.key !== item.key
      );
    },
  },
  mounted() {
    this.fetchCityList();
    this.fetchTaxTypeList();

    if (this.$refs.refFormContactManageAddress) {
      this.form = this.$refs.refFormContactManageAddress as FormModel;
    }

    if (this.formState.city) {
      this.onChangeCity(this.formState.city);
    }
  },
  watch: {
    address: {
      immediate: true,
      handler(value: ContactFormAddressState | undefined) {
        if (value) {
          this.formState = value;
        } else {
          this.formState = initContactFormAddressState();
        }
      },
    },
  },
  methods: {
    onChangeAsBill(checked: boolean) {
      if (!checked) {
        this.formState.isPrimaryBill = false;
      }
    },
    onChangeAsShip(checked: boolean) {
      if (!checked) {
        this.formState.isPrimaryShip = false;
      }
    },
    handleMenuPic({ key }: { key: "edit" | "delete" }, index: number) {
      if (key === "edit") {
        this.formState.pics[index].isEdit = true;
        return;
      }
      this.formState.pics.splice(index, 1);
    },
    editPic(index: number) {
      this.formState.pics[index].isEdit = true;
    },
    savePic(index: number) {
      this.formState.pics[index].isEdit = false;
    },
    deletePic(index: number) {
      this.formState.pics.splice(index, 1);
    },
    addPic() {
      const pic = initContactFormPicState();
      this.formState.pics.push(pic);
    },
    async fetchCityList(city?: string) {
      try {
        this.loading.city = true;
        const response = await contactServices.listRegionCity({ city });
        this.cityOptions = response.map(item => ({
          key: item,
          label: item,
          value: item,
        }));
      } finally {
        this.loading.city = false;
      }
    },
    async fetchPostalCodeList(params: RequestQueryParamsModel) {
      try {
        this.loading.postal = true;
        const response = await contactServices.listRegionCode(params);
        this.postalCodeOptions = response.map(item => ({
          key: item,
          label: item,
          value: item,
        }));
      } finally {
        this.loading.postal = false;
      }
    },
    onChangeCity(value: string) {
      const params = new RequestQueryParams();
      params.search = new SearchBuilder()
        .push(["city", value], { like: "both" })
        .build();
      this.fetchPostalCodeList(params);
    },
    async fetchTaxTypeList() {
      try {
        this.loading.taxType = true;
        const response = await useFindMasterType("TAX_TYPE");
        this.taxTypeOptions = response.map(item => ({
          key: item.value,
          label: item.value,
          value: item.value,
        }));
      } finally {
        this.loading.taxType = false;
      }
    },
    handleClose() {
      this.$emit("close");
      this.formState = initContactFormAddressState();
    },
    async handleSubmit() {
      try {
        await this.form?.validate();
        const payload: ContactFormAddressState = {
          ...this.formState,
          pics: this.formState.pics.map(item => ({
            ...item,
            isEdit: false,
          })),
        };
        this.$emit("submit", { payload });
        this.formState = initContactFormAddressState();
      } catch (error) {
        this.showNotifError("notif_validation_error");
      }
    },
    onSearchCity(value?: string) {
      this.fetchCityList(value?.toUpperCase());
    },
    onSearchPostalCode(value?: string) {
      const params = new RequestQueryParams();
      params.search = new SearchBuilder()
        .push(["city", this.formState.city], { like: "both" })
        .build();

      if (value) {
        params.search = new SearchBuilder()
          .push(["city", this.formState.city], { like: "both" })
          .and()
          .push(["code", value ?? ""], { like: "both" })
          .build();
      }
      this.fetchPostalCodeList(params);
    },
  },
});
