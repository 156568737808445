var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-list-item",
    {
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _vm.address.isPrimaryBill || _vm.address.isPrimaryShip
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_primary")) + " ")
                      ])
                    : _vm._e(),
                  _vm.address.pics.length
                    ? [
                        _c("a-icon", { attrs: { type: "user" } }),
                        _vm._v(" " + _vm._s(_vm.pics.length) + " ")
                      ]
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        size: "small",
                        icon: "edit",
                        "data-testid": "contact-address-manage-edit"
                      },
                      on: { click: _vm.handleEdit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_edit")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        size: "small",
                        type: "danger",
                        icon: "delete",
                        "data-testid": "contact-address-manage-delete"
                      },
                      on: { click: _vm.handleDelete }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_delete")) + " ")]
                  )
                ],
                2
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("a-list-item-meta", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("a-icon", { attrs: { type: "home" } }),
                _vm._v(" " + _vm._s(_vm.address.city) + " ")
              ]
            },
            proxy: true
          },
          {
            key: "description",
            fn: function() {
              return [
                _c(
                  "a-descriptions",
                  { attrs: { column: 3, layout: "vertical" } },
                  [
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_country") } },
                      [_vm._v(" " + _vm._s(_vm.address.country) + " ")]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_city_district") } },
                      [_vm._v(" " + _vm._s(_vm.address.city) + " ")]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_postal_code") } },
                      [_vm._v(" " + _vm._s(_vm.address.postalCode) + " ")]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_full_address"), span: 3 } },
                      [_vm._v(" " + _vm._s(_vm.address.address) + " ")]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_location"), span: 3 } },
                      [_vm._v(" " + _vm._s(_vm.address.location) + " ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }