var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_invoice_print_setting"),
        "destroy-on-close": "",
        visible: _vm.value,
        "ok-text": _vm.$t("lbl_print"),
        "cancel-text": _vm.$t("lbl_close"),
        "ok-button-props": {
          props: { type: "primary", loading: _vm.loading.print }
        }
      },
      on: { cancel: _vm.close, ok: _vm.validate }
    },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            model: _vm.formState,
            rules: _vm.formRules,
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_title"), prop: "selectedTitle" } },
            [
              _c("a-select", {
                staticClass: "w-100",
                attrs: {
                  loading: _vm.loading.title,
                  "show-search": "",
                  "filter-option": _vm.useLocalFilter,
                  "option-filter-prop": "children",
                  options: _vm.options
                },
                on: { search: _vm.onSearch },
                scopedSlots: _vm._u([
                  {
                    key: "dropdownRender",
                    fn: function(menu) {
                      return _c(
                        "div",
                        {},
                        [
                          _c("v-nodes", { attrs: { vnodes: menu } }),
                          _c("a-divider", { staticStyle: { margin: "4px 0" } }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "4px 8px",
                                cursor: "pointer"
                              },
                              on: {
                                mousedown: function(e) {
                                  return e.preventDefault()
                                },
                                click: _vm.addItem
                              }
                            },
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _vm._v(" " + _vm._s(_vm.$t("lbl_add")) + " ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }
                  }
                ]),
                model: {
                  value: _vm.formState.selectedTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "selectedTitle", $$v)
                  },
                  expression: "formState.selectedTitle"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: _vm.$t("lbl_use_value_as"), prop: "useValueAs" }
            },
            [
              _c("a-checkbox-group", {
                attrs: { options: _vm.valueOptions },
                model: {
                  value: _vm.formState.useValueAs,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "useValueAs", $$v)
                  },
                  expression: "formState.useValueAs"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }