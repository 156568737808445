






























































































































































import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useBank } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  ContactFormBankState,
  ContactFormState,
} from "@/store/models/Contact.model";
import { initContactFormBankState } from "@/store/resources/Contact.resource";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "FormContactBank",
  computed: {
    ...mapState({
      formState: (st: any) =>
        st.contactStore.contactFormState as ContactFormState,
    }),
  },
  data() {
    this.onSearchBank = debounceProcess(this.onSearchBank, 500);
    return {
      loading: {
        bank: false,
      },
      bankOptions: [] as Option[],
    };
  },
  mounted() {
    this.fetchBankList();
  },
  methods: {
    deleteBank(bank: ContactFormBankState) {
      const index = this.formState.banks.findIndex(
        item => item.key === bank.key
      );
      if (index === -1) return;
      this.formState.banks.splice(index, 1);
    },
    editBank(item: ContactFormBankState) {
      item.isEdit = true;
    },
    saveBank(item: ContactFormBankState) {
      item.isEdit = false;
    },
    onSearchBank(value?: string) {
      const params = new RequestQueryParams();
      if (value) {
        params.search = new SearchBuilder()
          .push(["name", value], { like: "both" })
          .build();
      }
      this.fetchBankList(params);
    },
    insertBank() {
      const bank = initContactFormBankState();
      this.formState.banks.push(bank);
    },
    async fetchBankList(params?: RequestQueryParamsModel) {
      const { findAllMasterBank } = useBank();
      try {
        this.loading.bank = true;
        const response = await findAllMasterBank(params);
        this.bankOptions = response.data.map(item => ({
          key: item.id,
          value: item.name,
          label: item.name,
        }));
      } finally {
        this.loading.bank = false;
      }
    },
  },
});
