var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      ref: "refFormContactManageAddress",
      attrs: { model: _vm.formState, rules: _vm.formRules },
      nativeOn: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit.apply(null, arguments)
        }
      }
    },
    [
      _c("fieldset", [
        _c(
          "legend",
          [
            _c("a-icon", { attrs: { type: "home" } }),
            _vm._v(" " + _vm._s(_vm.$t("lbl_address")) + " ")
          ],
          1
        )
      ]),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 8 } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_country"), prop: "country" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "ant-form-text",
                      attrs: { "data-testid": "contact-address-manage-country" }
                    },
                    [_vm._v(" " + _vm._s(_vm.formState.country) + " ")]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 8 } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_city_district"), prop: "city" } },
                [
                  _c("a-select", {
                    attrs: {
                      "data-testid": "contact-address-manage-city",
                      placeholder: _vm.$t("common.select-text", {
                        text: _vm.$t("lbl_city_district")
                      }),
                      "show-search": "",
                      loading: _vm.loading.city,
                      "filter-option": false,
                      options: _vm.cityOptions
                    },
                    on: { search: _vm.onSearchCity, change: _vm.onChangeCity },
                    model: {
                      value: _vm.formState.city,
                      callback: function($$v) {
                        _vm.$set(_vm.formState, "city", $$v)
                      },
                      expression: "formState.city"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 8 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_postal_code"),
                    prop: "postalCode"
                  }
                },
                [
                  _c("a-select", {
                    attrs: {
                      "data-testid": "contact-address-manage-postal-code",
                      placeholder: _vm.$t("common.select-text", {
                        text: _vm.$t("lbl_postal_code")
                      }),
                      "show-search": "",
                      loading: _vm.loading.postal,
                      "filter-option": false,
                      options: _vm.postalCodeOptions
                    },
                    on: { search: _vm.onSearchPostalCode },
                    model: {
                      value: _vm.formState.postalCode,
                      callback: function($$v) {
                        _vm.$set(_vm.formState, "postalCode", $$v)
                      },
                      expression: "formState.postalCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-model-item",
        {
          attrs: { label: _vm.$t("lbl_address"), prop: "address" },
          scopedSlots: _vm._u([
            {
              key: "extra",
              fn: function() {
                return [
                  _c("character-length", {
                    attrs: { value: _vm.formState.address }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("a-textarea", {
            attrs: {
              "allow-clear": "",
              "data-testid": "contact-address-manage-address",
              "auto-size": { minRows: 4 }
            },
            model: {
              value: _vm.formState.address,
              callback: function($$v) {
                _vm.$set(_vm.formState, "address", $$v)
              },
              expression: "formState.address"
            }
          })
        ],
        1
      ),
      _c(
        "a-form-model-item",
        {
          attrs: { label: _vm.$t("lbl_location"), prop: "location" },
          scopedSlots: _vm._u([
            {
              key: "extra",
              fn: function() {
                return [
                  _c("character-length", {
                    attrs: { value: _vm.formState.location }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("a-textarea", {
            attrs: {
              "allow-clear": "",
              "data-testid": "contact-address-manage-location",
              "auto-size": { minRows: 4 }
            },
            model: {
              value: _vm.formState.location,
              callback: function($$v) {
                _vm.$set(_vm.formState, "location", $$v)
              },
              expression: "formState.location"
            }
          })
        ],
        1
      ),
      _c(
        "a-form-model-item",
        { attrs: { label: _vm.$t("lbl_tax_type"), prop: "taxType" } },
        [
          _c("a-select", {
            attrs: {
              "data-testid": "contact-address-manage-tax-type",
              placeholder: _vm.$t("common.select-text", {
                text: _vm.$t("lbl_tax_type")
              }),
              "show-search": "",
              loading: _vm.loading.taxType,
              "allow-clear": "",
              options: _vm.taxTypeOptions
            },
            model: {
              value: _vm.formState.taxType,
              callback: function($$v) {
                _vm.$set(_vm.formState, "taxType", $$v)
              },
              expression: "formState.taxType"
            }
          })
        ],
        1
      ),
      _c(
        "fieldset",
        [
          _c(
            "legend",
            [
              _c("a-icon", { attrs: { type: "info-circle" } }),
              _vm._v(" " + _vm._s(_vm.$t("lbl_shipping_address")) + " ")
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "mb-0",
                      attrs: {
                        label: _vm.$t("lbl_use_as_shipping_address"),
                        prop: "asShip",
                        "label-align": "left",
                        "wrapper-col": { span: 12 },
                        "label-col": { span: 6 }
                      }
                    },
                    [
                      _c("a-switch", {
                        attrs: {
                          disabled: !_vm.isCreate,
                          "data-testid": "contact-address-manage-is-shipping"
                        },
                        on: { change: _vm.onChangeAsShip },
                        model: {
                          value: _vm.formState.asShip,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "asShip", $$v)
                          },
                          expression: "formState.asShip"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_primary_address"),
                        prop: "isPrimaryShip",
                        "label-align": "left",
                        "wrapper-col": { span: 12 },
                        "label-col": { span: 6 }
                      }
                    },
                    [
                      _c("a-switch", {
                        attrs: {
                          disabled:
                            !_vm.formState.asShip || _vm.hasPrimaryShiping,
                          "data-testid":
                            "contact-address-manage-shipping-is-primary"
                        },
                        model: {
                          value: _vm.formState.isPrimaryShip,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "isPrimaryShip", $$v)
                          },
                          expression: "formState.isPrimaryShip"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fieldset",
        [
          _c(
            "legend",
            [
              _c("a-icon", { attrs: { type: "info-circle" } }),
              _vm._v(" " + _vm._s(_vm.$t("lbl_billing_address")) + " ")
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "mb-0",
                      attrs: {
                        label: _vm.$t("lbl_use_as_billing_address"),
                        prop: "asBill",
                        "label-align": "left",
                        "wrapper-col": { span: 12 },
                        "label-col": { span: 6 }
                      }
                    },
                    [
                      _c("a-switch", {
                        attrs: {
                          "data-testid": "contact-address-manage-is-billing",
                          disabled: !_vm.isCreate
                        },
                        on: { change: _vm.onChangeAsBill },
                        model: {
                          value: _vm.formState.asBill,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "asBill", $$v)
                          },
                          expression: "formState.asBill"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_primary_address"),
                        prop: "isPrimaryBill",
                        "label-align": "left",
                        "wrapper-col": { span: 12 },
                        "label-col": { span: 6 }
                      }
                    },
                    [
                      _c("a-switch", {
                        attrs: {
                          disabled:
                            !_vm.formState.asBill || _vm.hasPrimaryBilling,
                          "data-testid":
                            "contact-address-manage-billing-is-primary"
                        },
                        model: {
                          value: _vm.formState.isPrimaryBill,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "isPrimaryBill", $$v)
                          },
                          expression: "formState.isPrimaryBill"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fieldset",
        [
          _c(
            "legend",
            [
              _c(
                "a-row",
                {
                  attrs: {
                    type: "flex",
                    justify: "space-between",
                    align: "middle"
                  }
                },
                [
                  _c(
                    "a-col",
                    [
                      _c("a-icon", { attrs: { type: "contacts" } }),
                      _vm._v(" " + _vm._s(_vm.$t("lbl_pic")) + " ")
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.addPic }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_add")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("lbl_total_items", {
                    total: _vm.formState.pics.length
                  })
                ) +
                " "
            )
          ]),
          _c("a-list", {
            attrs: {
              "item-layout": "horizontal",
              bordered: "",
              "data-source": _vm.formState.pics
            },
            scopedSlots: _vm._u([
              {
                key: "renderItem",
                fn: function(item, index) {
                  return [
                    _c(
                      "a-list-item",
                      [
                        item.isEdit
                          ? [
                              _c(
                                "a-row",
                                { attrs: { gutter: [16, 16], type: "flex" } },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            "wrapper-col": { span: 24 },
                                            "label-col": { span: 24 },
                                            label: _vm.$t("lbl_pic_name")
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              "allow-clear": "",
                                              required: "",
                                              "data-testid":
                                                "contact-address-manage-pic-name"
                                            },
                                            model: {
                                              value: item.name,
                                              callback: function($$v) {
                                                _vm.$set(item, "name", $$v)
                                              },
                                              expression: "item.name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_email"),
                                            "wrapper-col": { span: 24 },
                                            "label-col": { span: 24 }
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              "allow-clear": "",
                                              "data-testid":
                                                "contact-address-manage-pic-email"
                                            },
                                            model: {
                                              value: item.email,
                                              callback: function($$v) {
                                                _vm.$set(item, "email", $$v)
                                              },
                                              expression: "item.email"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_position"),
                                            "wrapper-col": { span: 24 },
                                            "label-col": { span: 24 }
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              "allow-clear": "",
                                              "data-testid":
                                                "contact-address-manage-pic-position"
                                            },
                                            model: {
                                              value: item.position,
                                              callback: function($$v) {
                                                _vm.$set(item, "position", $$v)
                                              },
                                              expression: "item.position"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_telephone"),
                                            "wrapper-col": { span: 24 },
                                            "label-col": { span: 24 }
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              "allow-clear": "",
                                              "data-testid":
                                                "contact-address-manage-pic-telephone"
                                            },
                                            model: {
                                              value: item.telephone,
                                              callback: function($$v) {
                                                _vm.$set(item, "telephone", $$v)
                                              },
                                              expression: "item.telephone"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_phone"),
                                            "wrapper-col": { span: 24 },
                                            "label-col": { span: 24 }
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              "allow-clear": "",
                                              "data-testid":
                                                "contact-address-manage-pic-phone"
                                            },
                                            model: {
                                              value: item.phone,
                                              callback: function($$v) {
                                                _vm.$set(item, "phone", $$v)
                                              },
                                              expression: "item.phone"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 24, align: "end" } },
                                    [
                                      _c(
                                        "a-space",
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                "data-testid":
                                                  "contact-address-manage-pic-delete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deletePic(index)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_delete")) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                                "data-testid":
                                                  "contact-address-manage-pic-save"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.savePic(index)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_save")) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _c("a-list-item-meta", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function() {
                                      return [
                                        _c("a-icon", {
                                          attrs: { type: "user" }
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.name +
                                                " " +
                                                (item.position
                                                  ? "(" + item.position + ")"
                                                  : "")
                                            ) +
                                            " "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "description",
                                    fn: function() {
                                      return [
                                        _c(
                                          "a-row",
                                          {
                                            attrs: {
                                              type: "flex",
                                              justify: "space-between",
                                              align: "middle"
                                            }
                                          },
                                          [
                                            _c(
                                              "a-col",
                                              [
                                                _c("a-space", [
                                                  item.telephone
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("a-icon", {
                                                            attrs: {
                                                              type: "phone"
                                                            }
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.telephone
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.phone
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("a-icon", {
                                                            attrs: {
                                                              type: "phone"
                                                            }
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.phone
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.email
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("a-icon", {
                                                            attrs: {
                                                              type: "mail"
                                                            }
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.email
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              [
                                                _c(
                                                  "a-dropdown",
                                                  {
                                                    attrs: {
                                                      placement: "topRight"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "overlay",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "a-menu",
                                                                {
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleMenuPic(
                                                                        $event,
                                                                        index
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "a-menu-item",
                                                                    {
                                                                      key:
                                                                        "edit",
                                                                      attrs: {
                                                                        "data-testid":
                                                                          "contact-address-manage-pic-edit"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "lbl_edit"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "a-menu-item",
                                                                    {
                                                                      key:
                                                                        "delete",
                                                                      attrs: {
                                                                        "data-testid":
                                                                          "contact-address-manage-pic-delete"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "lbl_delete"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("a-button", {
                                                      attrs: {
                                                        shape: "circle",
                                                        icon: "more",
                                                        size: "small",
                                                        "data-testid":
                                                          "contact-address-manage-pic-manage"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                      ],
                      2
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "a-space",
            [
              _c(
                "a-button",
                {
                  attrs: { "data-testid": "contact-address-manage-close" },
                  on: { click: _vm.handleClose }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: {
                    "data-testid": "contact-address-manage-save",
                    type: "primary",
                    "html-type": "submit"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }