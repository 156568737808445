




















































import { useFindMasterType, useMapMasterTypeToOptionAlt } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { ContactFormState } from "@/store/models/Contact.model";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "FormContactSupplier",
  computed: {
    ...mapState({
      formState: (st: any) =>
        st.contactStore.contactFormState as ContactFormState,
    }),
  },
  data() {
    return {
      loading: {
        type: false,
      },
      supplierTypeOptions: [] as Option[],
    };
  },
  mounted() {
    this.fetchSupplierTypeList();
  },
  methods: {
    async fetchSupplierTypeList() {
      try {
        this.loading.type = true;
        const response = await useFindMasterType("SUPPLIER_TYPE");
        this.supplierTypeOptions = useMapMasterTypeToOptionAlt(response);
      } finally {
        this.loading.type = false;
      }
    },
  },
});
